import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FAQContainer, FAQs } from '../components/FAQs'

import { Box, Flex } from 'grid-styled'
import CTA from '../components/CTA'
import GalleryContainer from '../components/GalleryContainer'
import GalleryImage from '../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info,
} from '.'
import Form from '../components/Form'
import { Text, GallerySection, Content } from '../components/styles'
import Button from '../components/Button'
import ContentCard from '../components/ContentCard'
import Hero from '../components/Hero'
import { Video } from '../components/procedures'

export default ({ data }) => (
  <Layout header="light">
    <Helmet>
      <title>Grants for Sports Aviation Clubs - QLD Sports Aviators</title>
      <meta
        name="description"
        content="Grants for sports aviation clubs in Queensland."
      />
    </Helmet>

    <HomeHeader slant={false}>
      <figure>
        <Img fluid={data.wing.childImageSharp.fluid} />
      </figure>
      <div>
        <h2>Grants</h2>
        <Text size="large">
          Grants for sports aviation clubs in Queensland.
        </Text>
      </div>
    </HomeHeader>

    <FAQContainer style={{ marginTop: -65 }}>
      <FAQs>
        <Box style={{ border: '1px solid #000', borderRadius: 10, padding: 40, boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)' }}>
          <h3 style={{ paddingTop: 0, color: '#c12422', textDecoration: 'underline' }}>Grant Applications</h3>
          <p>
            This is your opportunity to apply for a Sports Aviation Grant from the
            Queensland Sports Aviators (QSA) to foster the sports in Queensland. The
            maximum amount you can apply for is $2,000, and funds need to be spent
            within a year of receiving the funds.
          </p>
          <p>
            The purpose of this grant is to foster sports aviation in Queensland,
            which includes hang gliding, paragliding, paramotoring and weight shift
            microlighting.
          </p>
          <Box width="400px">
            <a href="/assets/qsa-form-v1.docx" target="_blank">
              <Button outline inverted small>
                Download Form to Apply for a Grant
              </Button>
            </a>
          </Box>
        </Box>

        <h4 style={{ paddingTop: 40, color: '#c12422' }}>Eligibility</h4>
        <p>
          To be eligible for the Sports Aviation Grant you must be:
        </p>
        <ul>
          <li>a current SAFA member</li>
          <li>a current resident of Queensland</li>
        </ul>
        <p>Applications from commercial operators like schools and tandem operators
          will not be considered.
        </p>
        <h4 style={{ paddingTop: 20, color: '#c12422' }}>Selection Criteria and Procedures</h4>
        <p>
          Applications will be considered by an Awards Subcommittee of three QSA
          members who will rank all applications according to the (1) prospective
          benefits to the sport, (2) alignment with QSA values and SAFA objectives
          and principles, (3) feasibility, and (4) past success of applicants
          in completing similar projects.
        </p>
        <h4 style={{ paddingTop: 20, color: '#c12422' }}>Outcomes</h4>
        <p>
          Outcomes will be announced by the end of January, 2025. Questions can be
          directed to info@queenslandsportsaviators.com.au
        </p>
      </FAQs>
    </FAQContainer>

  </Layout>
)

export const query = graphql`
  query GrantsQuery {
    wing: file(relativePath: { eq: "paraglide-club.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
